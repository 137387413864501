.adv-input-outer {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  --label-width: 100px;
}

.adv-input {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-radius: var(--border-radius);
  border: 1px solid var(--adv-baser);
  background-color: white;
  border: 1px solid var(--light-theme-light);
}

.adv-input__with-information {
  border-radius: var(--border-radius) 0 var(--border-radius) var(--border-radius);
}

.adv-input__vertical {
  flex-direction: column;
}

.adv-input label {
  margin-left: 10px;
  margin-right: 10px;
  width: var(--label-width);
}

@media only screen and (max-width: 800px) {
  .adv-input label {
    width: unset;
  }
  .adv-input-outer label {
    margin-bottom: 5px;
  }
}

.adv-input input {
  align-self: flex-start;
  flex-grow: 1;
  border-radius: var(--border-radius);
  outline: 0;
  padding: 10px;
  margin-right: 10px;
  color: inherit;
  background-color: white;
  border: 1px solid var(--light-theme-light);
}

@media only screen and (max-width: 900px) {
  .adv-input  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    justify-items: center;
  }

  .adv-input input, .adv-input input {
    align-self: unset;
    margin-right: unset;
  }
}

.adv-input__vertical label {
  width: 100%;
  align-self: flex-start;
  margin: 0 0 5px 0px;
}

.adv-input__vertical input {
  width: calc(100% - 20px);
}

.adv-input-information {
  align-self: flex-end;
  display: inline-block;
  color: var(--adv-mid);
  text-align: right;
  font-size: 0.8em;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  padding: 2px 15px;
  background-color: white;
  border: 1px solid var(--light-theme-light);
  border-bottom: none;
}

@keyframes adv-input-mega-highlight-animation {
  0% {
    border-color: var(--light-theme-light);
    background-color: rgba(255, 255, 255, 1);
  }
  40% {
    border-color: var(--positive);
    background-color: var(--positive-white);
  }
  60% {
    border-color: var(--positive);
    background-color: var(--positive-white);
  }
  100% {
    border-color: var(--light-theme-light);
    background-color: rgba(255, 255, 255, 1);
  }
}

.adv-input__mega-highlight {
  animation: adv-input-mega-highlight-animation 8s infinite;
}
