.separator {
  display: flex;
}

.separator__divider {
  flex-grow: 1;
  border-top: 1px solid var(--adv-baser);
  margin-top: 0.6em;
  border-color: var(--light-theme-light);
}

.separator__text {
  margin: 0 10px 0 10px;
}
