* {
  --light-grey: #cbcbcb;
  --mid-grey: #8c8c8d;
  --dark-grey: #494a4b;
  --off-black: #222;
  --just-off-white: #f8f8f8;
  --white: #fff;
  --off-white: #eee;
  --brand-color-1: #fd835d;
  --brand-light-color-1: #ffe8dd;
  --brand-dark-color-1: #311200;
  --brand-color-1__transparent: #ff9257BB;
  --brand-color-1-alt: #ff8c44;
  --brand-color-2: #252525;
  --brand-color-2-alt: #333;
  --popover-background-obscurer: rgba(0, 0, 0, 0.15);

  --nav-and-hud-min-height: 50px;
  --nav-and-hud-small-screen-search-height: 120px;

  --nice-blue: #223ad9;
  --positive: #12AF58;
  --positive-variant: #12da78;
  --positive-white: rgba(240, 255, 240, 1);
  --text-on-white-2: #444;
  --text-on-white-3: #777;
  --scroll-color: var(--adv-baser);
  --warning: #ff7d00;
  --warning-darker: #ff4400;
  --less-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1), 0 4px 5px 0 rgba(0, 0, 0, 0.1);
  --some-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.4), 0 4px 5px 0 rgba(0, 0, 0, 0.4);
  --more-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.6), 0 6px 8px 0 rgba(0, 0, 0, 0.6);
  --border-radius: 15px;
}

body {
  --adv-basest: #0a0a0a;
  --adv-baser: #1c1c1c;
  --adv-baser-near: #161616;
  --adv-baser-transparency-some: #1c1c1c33;
  --adv-base: #252525;
  --adv-detailest: var(--white);
  --adv-detailer: var(--off-white);
  --adv-detail: var(--light-grey);
  --adv-mid: var(--mid-grey);
  --adv-attention: #333;
  --adv-attention-near: #434343;
  --adv-attention-complement: var(--white);
  --light-theme-mid: #6a6a6a;
  --light-theme-light: #cecece;
  --light-theme-text-1: #333;
  --light-theme-text-1-near: #777;
  background-color: #fff;
  color: var(--light-theme-text-1);
}

::selection {
  background: var(--brand-color-1__transparent);
}
::-moz-selection {
  background: var(--brand-color-1__transparent);
}

@font-face {
  font-display: swap;
  font-family: inter;
  font-weight: 300;
  src: url('assets/fonts/inter/Inter-Light.ttf');
}
@font-face {
  font-display: swap;
  font-family: inter;
  src: url('assets/fonts/inter/Inter-Regular.ttf');
}
@font-face {
  font-display: swap;
  font-family: inter;
  src: url('assets/fonts/inter/Inter-Bold.ttf');
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-family: librebaskerville;
  src: url('assets/fonts/librebaskerville/LibreBaskerville-Regular.ttf');
}
@font-face {
  font-display: swap;
  font-family: librebaskerville;
  src: url('assets/fonts/librebaskerville/LibreBaskerville-Bold.ttf');
  font-weight: 600;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--brand-color-1) !important;
}

*::-webkit-scrollbar {
  width: 7px;
}
*::-webkit-scrollbar-track {
  border: 1px solid var(--mid-grey);
  background-color: var(--light-grey);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--scroll-color);
  outline: 1px solid var(--scroll-color);
}

body {
  font-size: 0.9em;
  font-family: inter;
  font-weight: 400;
}


@media only screen and (max-width: 700px) {
  body {
    font-size: 1.1em;
  }
}


button {
  font-family: inter;
}

.clickable {
  cursor: pointer;
}

.clickable-span {
  font-size: 0.9em;
  color: var(--text-on-white-2);
  text-decoration: underline;
  cursor: pointer;
}

.clickable-span:hover {
  color: var(--brand-color-1);
}

.common-button {
  display: inline-block;
  text-align: center;
  border: none;
  color: white;
  background-color: var(--brand-color-2);
  border-radius: var(--border-radius);
  max-width: 200px;
  min-width: 50px;
  padding: 10px 10px;
  transition: all 0.5s;
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (min-width: 700px) {
  .common-button {
    min-width: 120px;
  }
}

.common-button-lighter {
  background-color: var(--dark-grey);
}

.common-button-attention {
  background-color: var(--brand-color-1);
}

.use-fade {
  animation: fade 0.4s;
}

.common-button:hover {
  border-radius: var(--border-radius);
  transition: all 0.5s;
}

button {
  outline: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
}

input {
  outline: none;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes landing-in-animation {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes animated-text-shadow {
  0% {
    text-shadow:
            -2px -2px 3px rgba(0, 0, 0, 0.5),
            2px -2px 3px rgba(0, 0, 0, 0.5),
            -2px 2px 3px rgba(0, 0, 0, 0.5),
            2px 2px 3px rgba(0, 0, 0, 0.5);
  }
  50% {
    text-shadow:
            -2px -2px 3px rgba(0, 0, 0, 0.9),
            2px -2px 3px rgba(0, 0, 0, 0.9),
            -2px 2px 3px rgba(0, 0, 0, 0.9),
            2px 2px 3px rgba(0, 0, 0, 0.9);
  }
  100% {
    text-shadow:
            -2px -2px 3px rgba(0, 0, 0, 0.5),
            2px -2px 3px rgba(0, 0, 0, 0.5),
            -2px 2px 3px rgba(0, 0, 0, 0.5),
            2px 2px 3px rgba(0, 0, 0, 0.5);
  }
}

@keyframes suave_to_faint_glimmer {
    0%  { transform: scale(0.9, 1.1) rotate(0deg) translateY(-50px); opacity: 0; }
    50%  { transform: scale(1, 1) rotate(0deg) translateY(-30px); }
    100% { transform: scale(1,1) translateY(0); opacity: 0.2; }
}

@keyframes category-hover-animation {
    0%  { transform: rotate(0deg); }
    50%  { transform: rotate(180deg); }
    100% { transform: rotate(360deg); }
}

@keyframes faint-glimmer {
    0% {
        opacity: 0.2;
    }
    50% {
        opacity: 0.4;
    }
    100% {
        opacity: 0.2;
    }
}

@keyframes rotate-fade-in {
  0%  { opacity: 0; transform: translateY(30px) rotate(5deg);}
  100% { opacity: 1; transform: rotate(0deg);}
}

@keyframes suave {
  0%  { transform: scale(1.1, 1.1) rotate(20deg) translateY(-50px); opacity: 0; }
  50%  { transform: scale(1, 1) rotate(0deg) translateY(-30px); opacity: 1; }
  100% { transform: scale(1,1) translateY(0); opacity: 1; }
}

@keyframes fade-bounce {
  0%  { transform: scale(1.1, 1.1) translateY(-30px); opacity: 0; }
  50%  { transform: scale(1, 1) translateY(-30px); opacity: 1; }
  100% { transform: scale(1,1) translateY(0); }
}

@media only screen and (max-width: 700px) {
  @keyframes fade-bounce {
    0%  { transform: scale(1.1, 1.1) translateY(30px); opacity: 0; }
    50%  { transform: scale(1, 1) translateY(30px); opacity: 1; }
    100% { transform: scale(1,1) translateY(0); }
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes moveLeft {
  from {
    position: relative;
    right: 0px;
  }
  to {
    position: relative;
    right: 1000px;
  }
}

@keyframes moveRight {
  from {
    position: relative;
    left: 0px;
  }
  to {
    position: relative;
    left: 100px;
  }
}

@keyframes slow-exit {
  0%  { transform: scale(1, 1) translateY(0px); opacity: 1; }
  100%  { transform: scale(1, 1) translateY(-30px); opacity: 0; }
}

@media only screen and (max-width: 700px) {
  @keyframes slow-exit {
    0%  { transform: scale(1, 1) translateY(0px); opacity: 1; }
    100%  { transform: scale(1, 1) translateY(50px); opacity: 0; }
  }
}

@keyframes fade-swipe {
  from {
      top: -10vh;
  }
  to {
      top: 0px;
  }
}

@keyframes fade-swipe-reverse {
  from {
    top: 100vh;
  }
  to {
    top: 0px;
  }
}

@keyframes fade-swipe-for-fixed {
  from {
    opacity: 0;
    margin-top: -10vh;
  }
  to {
    opacity: 1;
    margin-top: 0px;
  }
}

@keyframes anim-grad-background {
  0% {
    background-color: var(--brand-color-1);
  }
  50% {
    background-color: var(--brand-color-1-alt);
  }
  100% {
    background-color: var(--brand-color-1);
  }
}

.useFade {
  animation: fade 1s ease-in;
}

.swipe-to-the-left {
  animation: fadeOut 0.2s ease-in forwards, moveLeft 0.2s ease-in forwards;
}

.swipe-to-the-right {
  animation: fadeOut 0.2s ease-in forwards, moveRight 0.2s ease-in forwards;
}

h1 {
  font-family: librebaskerville;
  font-size: 1.6em;
  font-weight: 500;
  text-align: left;
}

h2, h3, h4 {
  font-size: 1.2em;
  text-align: left;
  font-weight: 500;
}

h2 {
  padding: 0;
}

@media only screen and (max-width: 700px) {
  h1, h2, h3, h4 {
  }
}

.app {
  min-height: 100vh;
}

@media only screen and (max-width: 700px) {
  .app {
    margin: 0px;
    font-size: 0.8em;
  }
}

.white-link {
  color: white;
}

.pages-with-navbar-container {
  position: relative;
  top: 50px;
  min-height: calc(100vh - 50px);
}

@media only screen and (max-width: 1073px) {
  .pages-with-navbar-container {
    top: 0px;
  }
}

.main-page-content {
  margin: 0 17%;
}

@media only screen and (max-width: 700px) {
  .main-page-content {
    margin: 0 10px;
  }
}

.notify-user-container {
  position: fixed;
  width: calc(100% - 20px);
  max-width: calc(100% - 20px);
  bottom: 0;
  padding: 10px;
  background-color: var(--adv-base);
  z-index: 15;
  animation: fade-bounce 0.5s;
  color: white;
  font-size: 0.8em;
  box-shadow: var(--more-box-shadow);
}

.notify-user-container__callback-styling {
  background-color: var(--brand-color-1);
}

.notify-user-container__positive-styling {
  background-color: var(--positive);
}

.notify-user-container__bad-news-styling {
  background-color: var(--warning-darker);
}

@media only screen and (max-width: 700px) {
  .notify-user-container {
    bottom: unset;
    top: 0;
  }
}

.rating-stars-container {
  display: flex;
  align-items: center;
  color: white;
}

.rating-stars-count {
  font-weight: 600;
  font-size: 1em;
  margin-left: 5px;
  color: var(--adv-base);
}

.MuiSvgIcon-root.rating-star {
  font-weight: 600;
  font-size: 1em;
  margin-left: 5px;
}

.MuiSvgIcon-root.rating-star {
  color: var(--adv-base);
}

.slight-scrollbar::-webkit-scrollbar {
  width: 3px;
}
.slight-scrollbar::-webkit-scrollbar-track {
  border: 1px dashed var(--light-theme-light);
  background-color: var(--light-grey);
}
.slight-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--light-theme-mid);
  outline: none;
}

.slight-scrollbar-bottom::-webkit-scrollbar {
  height: 3px;
}
.slight-scrollbar-bottom::-webkit-scrollbar-track {
  border: 1px solid var(--mid-grey);
  background-color: var(--light-grey);
}
.slight-scrollbar-bottom::-webkit-scrollbar-thumb {
  background-color: var(--adv-base);
  outline: 1px solid var(--adv-base);
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
  width: 0px;
}
.no-scrollbar::-webkit-scrollbar-track {
  display: none;
  border: 0px dashed var(--light-theme-light);
  background-color: var(--light-grey);
}
.no-scrollbar::-webkit-scrollbar-thumb {
  display: none;
  background-color: var(--light-theme-mid);
  outline: none;
}

.frame-in-center {
  margin: 0 17%;
}

@media only screen and (max-width: 700px) {
  .frame-in-center {
    margin: 0 10px;
  }
}

.generic-page-left-margin {
  margin-left: 20px;
}

.generic-start-transparent {
  opacity: 0;
}

.generic-fade-in {
  animation: fade 0.5s both !important;
}

.generic-delayed-fade-in {
  animation: fade 0.5s both !important;
}

.bold {
   font-weight: bold;
}

.info-on-hover__container {

}

.info-on-hover__container:hover .info-on-hover__info {
  display: block;
  position: absolute;
  background-color: white;
  border: 1px solid var(--positive);
  width: calc(80% - 20px);
  max-width: calc(80% - 20px);
  min-width: calc(80% - 20px);
  padding: 10px;
  border-radius: 5px var(--border-radius) var(--border-radius) var(--border-radius);
  color: var(--light-theme-text-1);
  left: 60%;
  box-shadow: var(--less-box-shadow);
  z-index: 10;
}

.info-on-hover__container:hover .info-on-hover__info__left {
  left: unset;
  right: 30%;
  border-radius: var(--border-radius) 5px var(--border-radius) var(--border-radius);
}
