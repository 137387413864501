.loader-container {
    width: 100%;
    margin: 50px 0px;
    display: flex;
    justify-content: center;
}

.loading-container__in-button {
    min-width: 60px;
    margin: 0px;
    margin-top: 3px;
}