.auth-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: var(--border-radius);
    box-shadow: var(--less-box-shadow);
    transition: all 0.5s;
    border: 1px solid var(--light-theme-light);
    background-color: white;
    color: var(--light-theme-text-1);
}

.auth-container h2 {
    margin: 10px 0 0px 0;
}

.auth-container .adv-input-outer {
    margin-bottom: 20px;
}

.auth-container:hover {
    border-radius: var(--border-radius);
    transition: all 0.5s;
}

.auth-email-sign-in-container {
    width: calc(100%);
    margin: 10px 0 0;
    display: flex;
    flex-direction: column;
}

.auth-google-sign-in-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: flex-start;
}

.in-form-auth-heading-verb {
    font-size: 1em;
    font-weight: normal;
    letter-spacing: 0.08em;
    padding: 25px 0 20px 0;
    margin: 25px 0 0px 0;
    border-top: 1px solid var(--mid-grey);
}

.in-form-auth-input-container {
    display: flex;
    align-items: center;
    width: 100%;
}

@media only screen and (max-width: 900px) {
    .in-form-auth-input-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }
}

.in-form-terms-and-conditions {
    text-align: center;
    color: var(--text-on-white-3);
    display: block;
    font-size: 0.8em;
    margin: 10px 0;
}

.in-form-terms-and-conditions__on-white {
    color: var(--off-black);
}

.in-form-auth-vertical-space {
    min-height: 50px;
}

.auth-errors {
    font-weight: bolder;
    background-color: var(--warning-darker);
    color: white;
    margin-top: 10px;
    padding: 15px;
    border-radius: var(--border-radius);
}

.sign-in-with-google-button-and-image {
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-items: center;
}

.sign-in-with-google-image {
    height: 32px;
    width: 32px;
    margin-right: 10px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    font-weight: bold;
    text-transform: uppercase;
}

.sign-in-with-google-terms-and-conditions {
    color: var(--mid-grey);
    font-size: 0.8em;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
}

@media only screen and (max-width: 1000px) {
    .sign-in-with-google-terms-and-conditions {
        max-width: unset;
    }
}

.sign-in-with-google-terms-and-conditions-link {
    color: var(--mid-grey);
}

.sign-in-with-google-terms-and-conditions__on-white {
    color: var(--dark-grey);
}

.sign-in-with-google-terms-and-conditions-link__on-white {
    color: var(--dark-grey);
}

.agree-to-terms-buttons-section {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}

.common-button.agree-to-terms-button {
    background-color: var(--mid-grey);
    margin-bottom: 10px;
}

.agree-to-terms-buttons-section .adv-button {
    margin-bottom: 10px;
    min-width: 100%;
}

.sign-in-button {
    align-self: flex-end;
    justify-self: flex-end;
    margin-top: 30px;
    padding: 20px 40px;
}

.inform-auth-clickable-span {
    margin-top: 5px;
    font-size: 0.9em;
    color: var(--text-on-white-3);
    text-decoration: underline;
    cursor: pointer;
}

.in-form-auth-separator {
    margin-top: 10px;
    margin-bottom: 10px;
    color: var(--adv-mid);
}

.in-form-auth__create-password-instruction {
    text-align: center;
    margin-bottom: 5px;
}
