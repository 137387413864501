.advance-loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: white;
}

@keyframes advance-loads-in {
  0% {
    opacity: 0;
    filter: grayscale(1);
    height: 100px;
    width: 250px;
  }
  60% {
    opacity: 1;
    filter: grayscale(1);
    height: 100px;
    width: 250px;
  }
  80% {
    opacity: 0.8;
    filter: grayscale(1);
    height: 100px;
    width: 180px;
  }
  100% {
    opacity: 1;
    filter: grayscale(0);
    height: 100px;
    width: 180px;
  }
}

@keyframes advance-loader-loads-in {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0.0;
  }
  100% {
    opacity: 1;
  }
}

.advance-is-loading-image {
  background-image: url("../../assets/logo-words.webp"), url("../../assets/logo-words.png");
  background-size: contain;
  background-repeat: no-repeat;
  animation: advance-loads-in 1.3s forwards ease-in-out;
}

.advance-loading-loader {
  animation: advance-loader-loads-in 2s;
}
