.adv-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--adv-baser-near);
  border-radius: var(--border-radius);
  width: 160px;
  padding: 10px 10px;
  transition: all 0.5s;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  box-shadow: var(--less-box-shadow);
  min-height: 40px;
  color: var(--light-theme-text-1);
  background-color: white;
  border-color: var(--light-theme-light);
}

@media only screen and (max-width: 700px) {
  .adv-button {
    width: unset;
    min-width: unset;
  }
}


.adv-button__full-width {
  width: 100%;
}

.adv-button__selected {
  box-shadow: none;
}

.adv-button .MuiSvgIcon-root {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 20px;
  height: 20px;
  opacity: 0.2;
  transition: all 0.5s;
}

.adv-button:hover .MuiSvgIcon-root {
  color: var(--brand-color-1);
  position: absolute;
  top: 3px;
  transform: rotate(360deg);
  opacity: 0.2;
}

.adv-button__selected .MuiSvgIcon-root {
  color: var(--brand-color-1);
  position: absolute;
  top: 3px;
  opacity: 0.8;
}

.adv-button:hover {
  box-shadow: var(--some-box-shadow);
  background-color: white;
  border-color: var(--light-theme-mid);
}

.adv-button-loading-state-loader {
  position: absolute;
  content: "";
}

.adv-button-loading-state-loader svg {
  stroke: var(--brand-color-1);
}

.adv-button__text {}

.adv-button__text__expandable {
  display: flex;
  justify-content: flex-end;
  margin-left: 10px;
}

.adv-button .adv-button-expandable.MuiSvgIcon-root {
  justify-self: flex-end;
  position: relative;
  top: unset;
  transform: rotate(0deg);
}

.adv-button:hover .adv-button-expandable.MuiSvgIcon-root {
  width: 0;
  color: var(--brand-color-1);
  position: relative;
  opacity: 1;
  transform: rotate(360deg);
}

.adv-button__icon-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.generic-dropdown-option {
  border-radius: 0;
  border-top: none;
}

.generic-dropdown-option:first-child {
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  border-top: 1px solid var(--light-theme-light);
}

.generic-dropdown-option:first-child:hover {
  border-top: 1px solid var(--light-theme-text-1);
}

.generic-dropdown-option:last-child {
  border-bottom: inherit;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}
